<template>
  <div class="header">
    <cLogo :white="white" />
    <div class="header__nav">
      <p class="header__link" v-on:click="state != ConfigureState.Select ? goTo(ConfigureState.Select) : null" :class="{ selected: state == ConfigureState.Select, white: white }">Komfur</p>
      <p class="header__link selected" v-if="state != ConfigureState.Select" :class="{ white: white }">
        {{ product.name }}
      </p>
    </div>
  </div>
</template>

<script>
import cLogo from '@/components/cLogo.vue'
import base from '@/mixins/base'

export default {
  name: 'cHeader',
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    cLogo,
  },
  mixins: [base],
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_utilities';
.header {
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 20px;
  display: flex;
  gap: 35px;
  align-items: center;
  &__nav {
    display: flex;
    gap: 40px;
    @media only screen and (max-width: $LG) {
      display: none;
    }
  }
  &__link {
    cursor: pointer;
    margin: 0;
    text-align: center;
    color: #333333;
    opacity: 50%;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    padding-bottom: 4px;
    &.white {
      color: white;
    }
    &.selected {
      cursor: auto;
      opacity: 100%;
      border-bottom: 2px solid #a7976e;
    }
  }
}
</style>

import { getFunctions, httpsCallable } from 'firebase/functions'

export default {
  namespaced: true,
  actions: {
    request(_, { request, configuration }) {
      const _request = httpsCallable(getFunctions(), 'request')
      return _request({
        request: JSON.parse(JSON.stringify(request)),
        configuration: JSON.parse(JSON.stringify(configuration)),
      })
    },
  },
}

<template>
  <b-container fluid class="sent">
    <b-row class="row">
      <b-col cols="12" lg="6" class="sent__left">
        <cHeader :white="true" />
        <div class="sent__settings">
          <div class="sent__body">
            <div class="sent__main">
              <p class="sent__title">Tak, din konfiguration er sendt</p>
              <p class="sent__message">Vi kontakter dig hurtigst muligt med yderligere information om din forespørgsel.</p>
            </div>
            <div class="footer">
              <img class="footer__avatar" src="@/assets/img/avatar.png" alt="avatar" />
              <div class="footer__description">
                <p>Imran Arshad</p>
                <p>Indehaver af Køl og Komfur</p>
                <p>Email: <a href="mailto:info@kologkomfur.dk">info@kologkomfur.dk</a></p>
                <p>Telefon: <a href="tel:info@kologkomfur.dk">+45 35 81 11 05</a></p>
              </div>
            </div>
            <div class="footer__social">
              <p class="sent__message">Hold dig opdateret på eksklusive nyheder til dit køkken:</p>
              <p><a href="https://www.instagram.com/kologkomfur/">Instagram</a></p>
              <p><a href="https://www.facebook.com/kologkomfur">Facebook</a></p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="6" class="sent__right">
        <div class="sent__preview">
          <cPreview />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import base from '@/mixins/base'
import cHeader from '@/components/cHeader.vue'
import cPreview from '@/components/cPreview.vue'

export default {
  name: 'lSent',
  components: {
    cHeader,
    cPreview,
  },
  mixins: [base],
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_utilities';

.sent {
  height: 100vh;
  padding: 0 !important;
  .row {
    height: 100%;
    margin: 0 !important;
  }
  &__left {
    height: 100%;
    padding: 0 !important;
    background-color: #292b29;
  }
  &__right {
    height: 100%;
    padding: 0 !important;
    @media only screen and (max-width: $LG) {
      display: none;
    }
  }
  &__settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  &__body {
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    padding: 122px 20%;
    overflow-y: scroll;
    @media only screen and (max-width: $LG) {
      padding: 104px 30px;
    }
  }
  &__main {
    margin-bottom: 49px;
  }
  &__preview {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
  &__title {
    font-size: 28px;
    line-height: 47px;
    margin-bottom: 22;
    color: white;
    font-weight: 500;
    @media only screen and (max-width: $LG) {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 15px;
    }
  }
  &__message {
    font-size: 15px;
    line-height: 20px;
    color: white;
    font-weight: 500;
    margin-bottom: 30px;
  }
  &__description {
    font-size: 15px;
    line-height: 20px;
    margin: 0;
    color: white;
    font-weight: 300;
  }
  .footer {
    display: flex;
    align-items: center;
    gap: 35px;
    &__avatar {
      height: 86px;
      width: 86px;
      border-radius: 50%;
    }
    &__description {
      p {
        font-size: 14px;
        margin: 0;
        color: #ffffff;
        a {
          color: #a7976e;
        }
      }
    }
    &__social {
      margin-top: 79px;
      p {
        font-size: 14px;
        margin: 0;
        color: #ffffff;
        a {
          color: #a7976e;
        }
      }
    }
  }
}
</style>

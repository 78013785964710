import { ConfigureState } from '@/utils'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      ConfigureState,
    }
  },
  computed: {
    ...mapGetters(['product', 'categories', 'options', '_options', 'price', 'discount']),
    ...mapState({
      state: (state) => state.state,
      first: (state) => state.first,
      products: (state) => state.api.cache.products,
      settings: (state) => state.api.cache.settings,
      pages: (state) => state.api.cache.pages,
    }),
  },
  methods: {
    ...mapActions(['goTo', 'next', 'pervious', 'restart', 'reset', 'select', 'toggleOption', 'request', 'flag']),
    //todo move to store if possible
    isOptionSelected(option) {
      return this.$store.state.configuration.options.includes(option._reference.id)
    },
    onDiscount(option) {
      return option.discount && option.discount != 0
    },
  },
}

<template>
  <b-container fluid class="select">
    <cHeader />
    <div class="select__header">
      <h1 class="select__title">Steel Cucine</h1>
      <p class="select__description">Vælg og konfigurerer dit komfur, så det er tilpasset dit drømmekøkken.</p>
    </div>
    <b-row class="products">
      <b-col
        class="product"
        cols="12"
        xl="4"
        md="6"
        v-for="product in sortedProducts"
        :key="product.id"
        v-on:click="
          select(product)
          next()
        ">
        <div class="button">Konfigurer</div>
        <div class="product__image">
          <img :src="product.image" :alt="product.name" />
        </div>
        <div>
          <p class="product__name">{{ product.name }}</p>
          <p class="product__description">{{ product.description }}</p>
          <p class="product__price">Fra {{ formatToCurrency(product.price || 0, Currency.DKK) }}</p>
        </div>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col class="article" cols="12" md="6">
        <p class="article__title">Induktion eller gas komfur</p>
        <p class="article__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </b-col>
      <b-col class="article" cols="12" md="6">
        <p class="article__title">Vedligeholdelse og rengøring af komfur</p>
        <p class="article__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </b-col>
      <b-col class="article" cols="12" md="6">
        <p class="article__title">Levering til kansten</p>
        <p class="article__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </b-col>
      <b-col class="article" cols="12" md="6">
        <p class="article__title">Garanti</p>
        <p class="article__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </b-col>
    </b-row> -->
    <a class="select__copyright" href="https://kombiner.webflow.io/" target="_blank">Powered by KOMBINER</a>
  </b-container>
</template>

<script>
import cHeader from '@/components/cHeader.vue'
import { formatToCurrency, Currency } from '@/utils'
import base from '@/mixins/base'

export default {
  name: 'lSelect',
  mixins: [base],
  components: {
    cHeader,
  },
  computed: {
    sortedProducts() {
      return this.products.slice().sort((a, b) => a.price - b.price)
    },
  },
  data() {
    return {
      Currency,
    }
  },
  mounted() {
    if (this.first) {
      this.flag()
    } else {
      this.$router.replace({
        path: '/',
      })
    }
  },
  methods: {
    formatToCurrency,
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_utilities';

.select {
  max-width: 1380px;
  margin: 0 auto;
  padding: 24px 24px 0 24px;
  &__header {
    margin-top: 152px;
    margin-bottom: 140px;
    text-align: center;
  }
  &__title {
    font-size: 37px;
    line-height: 62px;
    color: #333;
  }
  &__description {
    font-size: 16px;
    line-height: 22px;
    max-width: 387px;
    color: #333333;
    opacity: 50%;
    margin: 0 auto;
  }
  .row:hover .product:not(:hover) {
    filter: grayscale(100%);
    opacity: 0.5;
  }
  &__copyright {
    margin-top: 80px;
    padding-bottom: 127px;
    text-transform: uppercase;
    color: #707070;
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 19px;
    text-align: center;
    display: block;
    text-decoration: none;
  }
}
.products {
  margin-bottom: 150px;
}
.product {
  cursor: pointer;
  text-align: center;
  position: relative;
  .button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000000;
    transform: translate(-50%, -50%);
    background-color: #d5bd87;
    color: white;
    font-size: 14px;
    padding: 1rem;
    display: none;
    border-radius: 2px;
  }
  &:hover {
    .button {
      display: block;
    }
  }
  &__image {
    padding: 10%;
    img {
      max-width: 100%;
      transition: all 0.2s ease;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  &__name {
    font-size: 22px;
    line-height: 34px;
    margin: 0;
    color: #333;
  }
  &__description {
    max-width: 250px;
    margin: 0 auto !important;
    font-size: 14px;
    letter-spacing: 19;
    color: #333333;
    opacity: 50%;
    margin: 0;
  }
  &__price {
    margin-top: 10px;
    font-size: 14px;
  }
}
.article {
  margin-bottom: 30px;
  &__title {
    font-size: 24px;
    line-height: 34px;
    color: #333333;
  }
  &__description {
    font-size: 14px;
    line-height: 23px;
    color: #333333;
    opacity: 50%;
  }
}
// #endregion
</style>

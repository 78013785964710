<template>
  <a class="link" href="https://kologkomfur.dk/" target="_blank">
    <img v-if="white" class="logo" src="https://firebasestorage.googleapis.com/v0/b/kologkomfur-7b847.appspot.com/o/K%C3%B8l%20og%20Komfur%20-%20Logo%20SVG%202022.svg?alt=media&token=d8bf8992-a0a1-4177-ba6e-39808e0a86e4" alt="logo" />
    <img v-else class="logo" src="https://firebasestorage.googleapis.com/v0/b/kologkomfur-7b847.appspot.com/o/kol-og-komfur.png?alt=media&token=47db0933-1e3d-4cc2-9405-cbc749c59083" alt="logo" />
  </a>
</template>

<script>
import base from '@/mixins/base'

export default {
  name: 'cLogo',
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [base],
}
</script>

<style scoped lang="scss">
.link {
  height: 64px;
  width: 64px;
}
.logo {
  max-height: 100%;
  max-width: 100%;
}
</style>

<template>
  <div class="home">
    <template v-if="busy">
      <div class="spinner">
        <div>
          <b-spinner></b-spinner>
          <p>Indlæser konfigurator</p>
        </div>
      </div>
    </template>
    <template v-else>
      <lSelect v-if="state == ConfigureState.Select" />
      <lConfigure v-if="state == ConfigureState.Configure" />
      <lRequest v-if="state == ConfigureState.Request" />
      <lSent v-if="state == ConfigureState.Sent" />
    </template>
  </div>
</template>

<script>
import base from '@/mixins/base'
import lSelect from '@/components/lifecycle/lSelect.vue'
import lConfigure from '@/components/lifecycle/lConfigure.vue'
import lRequest from '@/components/lifecycle/lRequest.vue'
import lSent from '@/components/lifecycle/lSent.vue'
import { ConfigureState } from '@/utils'
import { mapState } from 'vuex'

export default {
  name: 'vHome',
  mixins: [base],
  components: {
    lSelect,
    lConfigure,
    lRequest,
    lSent,
  },
  computed: {
    ...mapState(['state']),
  },
  data() {
    return {
      busy: true,
      ConfigureState,
    }
  },
  created() {
    if (this.$route.params.product) {
      this.busy = false
      let product = this.products.filter((product) => {
        return product.url == this.$route.params.product
      })[0]
      if (product) {
        this.select(product)
        this.next()
        this.busy = false
      } else {
        this.$router.push('/')
      }
    } else {
      this.busy = false
    }
  },
}
</script>

<style lang="scss"></style>

<template>
  <b-container fluid class="request">
    <b-row class="row">
      <b-col cols="12" lg="6" class="request__left">
        <cHeader :white="true" />
        <div class="request__settings">
          <template v-if="!busy">
            <div class="request__body">
              <div class="request__header">
                <p class="header__back" v-on:click="pervious()">
                  <b-icon icon="arrow-left" aria-hidden="true"></b-icon>
                  Tilbage
                </p>
                <p class="request__title">Send forespørgsel</p>
              </div>
              <div class="request__main">
                <div class="configuration">
                  <div v-b-toggle.collapse-1 class="configuration__toggle">
                    <div class="configuration__left">
                      <p class="configuration__product">{{ product.name }}</p>
                      <p class="configuration__sku">Varenummer: {{ product.sku }}</p>
                    </div>
                    <div class="configuration__price">
                      <div>
                        <p v-if="discount != 0" class="discount">
                          {{ formatToCurrency(price - discount, Currency.DKK) }}
                        </p>
                        <p class="price" :class="{ strike: discount != 0 }">
                          {{ formatToCurrency(price, Currency.DKK) }}
                        </p>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="6.945" height="4.096" viewBox="0 0 13.89 8.192">
                        <path id="Path_25" data-name="Path 25" d="M1687.379,45.323l6.2,6.329,6.454-6.329" transform="translate(-1686.754 -44.698)" fill="none" stroke="black" stroke-width="1.75" />
                      </svg>
                    </div>
                  </div>
                  <b-collapse id="collapse-1" class="configuration__collapse">
                    <b-card class="configuration__list" no-body>
                      <div class="category" v-for="category in categories" :key="category.id">
                        <p class="category__name">{{ category.name }}</p>
                        <template v-for="option in category.options">
                          <div v-if="isOptionSelected(option)" class="option" :key="option.id">
                            <p class="option__name">{{ option.name }}</p>
                            <div class="option__price">
                              <p v-if="onDiscount(option)" class="discount">{{ formatToCurrency(option.price - option.discount, Currency.DKK) }}</p>
                              <p class="price" :class="{ strike: onDiscount(option) }">{{ formatToCurrency(option.price || 0, Currency.DKK) }}</p>
                            </div>
                          </div>
                        </template>
                      </div>
                    </b-card>
                  </b-collapse>
                </div>
                <b-form class="form">
                  <p class="form__title">Dine kontaktoplysninger</p>
                  <div class="form__group">
                    <b-form-input v-model="form.firstname" placeholder="Fornavn" required></b-form-input>
                    <b-form-input v-model="form.lastname" placeholder="Efternavn" required></b-form-input>
                  </div>
                  <div class="form__group">
                    <b-form-input v-model="form.email" type="email" placeholder="Email" required></b-form-input>
                    <b-form-input v-model="form.phone" type="tel" placeholder="Telefon" required></b-form-input>
                  </div>
                  <b-form-input v-model="form.address" placeholder="Adresse" required></b-form-input>
                  <div class="form__group">
                    <b-form-input v-model="form.zip_code" placeholder="Postnummer" required></b-form-input>
                    <b-form-input v-model="form.city" placeholder="By" required></b-form-input>
                  </div>
                </b-form>
              </div>
              <div class="footer">
                <p class="footer__back" v-on:click="pervious()">
                  <b-icon icon="arrow-left" aria-hidden="true"></b-icon>
                  Tilbage
                </p>
                <p class="footer__button" v-on:click="submit()">Bliv kontaktet</p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="spinner">
              <div>
                <b-spinner></b-spinner>
                <p style="color: white">Behandler forespørgsel</p>
              </div>
            </div>
          </template>
        </div>
      </b-col>
      <b-col cols="12" lg="6" class="request__right">
        <div class="request__preview">
          <cPreview />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import base from '@/mixins/base'
import cHeader from '@/components/cHeader.vue'
import cPreview from '@/components/cPreview.vue'
import { formatToCurrency, Currency } from '@/utils'

export default {
  name: 'lRequest',
  components: {
    cHeader,
    cPreview,
  },
  mixins: [base],
  data() {
    return {
      Currency,
      busy: false,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        zip_code: '',
      },
    }
  },
  methods: {
    formatToCurrency,
    async submit() {
      this.busy = true
      try {
        await this.request({ request: this.form })
        this.next()
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_utilities';

.request {
  height: 100vh;
  padding: 0 !important;
  .row {
    height: 100%;
    margin: 0 !important;
  }
  &__left {
    height: 100%;
    padding: 0 !important;
    background-color: #292b29;
  }
  &__right {
    height: 100%;
    padding: 0 !important;
    @media only screen and (max-width: $LG) {
      display: none;
    }
  }
  &__settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    @media only screen and (max-width: $LG) {
      justify-content: flex-start;
    }
  }
  &__preview {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
  &__body {
    display: flex;
    position: relative;
    flex-direction: column;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 122px 20%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    @media only screen and (max-width: $LG) {
      padding: 104px 30px;
    }
  }
  &__header {
    margin-bottom: 35px;
    @media only screen and (max-width: $LG) {
      margin-bottom: 17px;
    }
  }
  .header {
    &__back {
      display: none;
      color: #707070;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 0.8px;
      margin: 0;
      margin-bottom: 44px;
      @media only screen and (max-width: $LG) {
        display: block;
      }
    }
  }
  &__title {
    font-size: 24px;
    line-height: 29px;
    color: white;
    margin: 0;
    @media only screen and (max-width: $LG) {
      font-size: 14px;
      line-height: 17px;
    }
  }
  .configuration {
    margin-bottom: 27px;
    &__toggle {
      padding: 22px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #ffffff33;
    }
    &__product {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 8.8px;
      color: white;
    }
    &__sku {
      font-size: 13px;
      font-weight: 300;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.71);
      margin: 0;
    }
    &__price {
      display: flex;
      align-items: center;
      .price {
        font-size: 14px;
        line-height: 22px;
        margin: 0;
        color: white;
        margin-right: 10px;
        &.strike {
          text-decoration: line-through;
          text-decoration-color: #d5bd87;
        }
      }
      .discount {
        font-size: 14px;
        line-height: 22px;
        margin: 0;
        color: white;
        margin-right: 10px;
      }
      svg {
        margin-left: 8px;
        path {
          stroke: white;
        }
      }
    }
    &__collapse {
      margin-top: 0.5rem;
    }
    &__list {
      padding: 0.375rem 0.75rem;
    }
  }
  .category {
    &__name {
      margin-bottom: 0 !important;
      margin-top: 10px;
      color: white;
    }
  }
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__name {
      font-size: 15px;
      color: #707070;
      margin: 0;
    }
    &__price {
      .price {
        color: white;
        margin: 0;
        font-size: 15px;
        &.strike {
          text-decoration: line-through;
        }
      }
      .discount {
        color: #d5bd87;
        margin: 0;
        font-size: 15px;
      }
    }
  }
  .form {
    > *:not(:last-child):not(:first-child) {
      margin-bottom: 16px;
    }
    &__title {
      color: white;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 16px;
    }
    &__group {
      display: flex;
      gap: 16px;
    }
  }
  .footer {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer {
      &__back {
        color: #707070;
        cursor: pointer;
        font-size: 14px;
        letter-spacing: 0.8px;
        margin: 0;
        @media only screen and (max-width: $LG) {
          display: none;
        }
      }
      &__button {
        background-color: #c1ad7e;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 2px;
        line-height: 24px;
        padding: 15px 58px;
        text-align: center;
        color: white;
        margin: 0;
        @media only screen and (max-width: $LG) {
          position: fixed;
          bottom: 0px;
          width: 100%;
          left: 0;
        }
      }
    }
  }
}
.card {
  border: none;
  background: none;
}
input,
select {
  height: 45px;
  font-weight: 300;
  background-color: #212221;
  color: white;
  border: none;
  font-size: 14px;
  &::placeholder {
    // color: rgba(255, 255, 255, 0.35);
    color: rgba(255, 255, 255, 0.35);
  }
  &:focus {
    background-color: #212221;
    color: white;
    outline: none;
    box-shadow: none;
  }
}
</style>

<template>
  <div class="preview">
    <template v-for="category in sortedCategories">
      <template v-for="option in category.options">
        <transition appear name="fade" :key="option._reference.id">
          <img :style="{ 'z-index': category.order }" class="preview__image" v-if="isOptionSelected(option) && option['front']" :src="option['front']" :alt="option.name" :key="option._reference.id" />
        </transition>
      </template>
    </template>
  </div>
</template>

<script>
import base from '@/mixins/base'

export default {
  name: 'cPreview',
  mixins: [base],
  computed: {
    sortedCategories() {
      return this.categories.slice().sort((a, b) => a.order - b.order)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_utilities';
.preview {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 50%;
  &__image {
    position: absolute;
    height: 100%;
  }
}
</style>

export const ConfigureState = Object.freeze({
  Select: Symbol('select'),
  Configure: Symbol('configure'),
  Request: Symbol('request'),
  Sent: Symbol('sent'),
})

export const formatToCurrency = function (n, currency) {
  switch (currency) {
    case Currency.DKK:
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' kr.'
  }
}

export const Currency = Object.freeze({
  DKK: Symbol('dkk'),
  EURO: Symbol('euro'),
})

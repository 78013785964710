import { ConfigureState } from '@/utils'
import Vue from 'vue'
import Vuex from 'vuex'
import router from './../router'
import api from './api'
import authentication from './authentication'
import functions from './functions'
import { SET_CATEGORIES, SET_CONFIGURATION, SET_FIRST, SET_OPTIONS, SET_PRODUCTS, SET_SETTINGS, SET_STATE } from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    state: undefined,
    first: true,
    configuration: {
      product: {},
      options: [],
    },
    request: {},
  },
  getters: {
    /**
     * Get the product for the current configuration, if any.
     */
    product(state) {
      return state.api.cache.products[state.api.cache.products.findIndex((index) => index._reference.id == state.configuration.product)]
    },
    /**
     * Get the available categories for the current configuration, if any.
     */
    categories(state, getters) {
      let categories = []
      getters.product.categories.forEach((category) => {
        let _category = state.api.cache.categories[state.api.cache.categories.findIndex((index) => index._reference.id == category.id)]
        _category.options = getters._options.filter((option) => option.category.id == _category._reference.id)
        categories.push(_category)
      })
      return categories
    },
    /**
     * Get the avaiable options for current product, if any.
     */
    _options(state) {
      return state.api.cache.options.filter((option) => option.product.some((product) => product.id === state.configuration.product)).sort((a, b) => (a.standard === b.standard ? 0 : a.standard ? -1 : 1))
    },
    /**
     * Get the selected options for current configuration, if any.
     */
    options(state) {
      let options = []
      state.configuration.options.forEach((option) => {
        options.push(state.api.cache.options[state.api.cache.options.findIndex((index) => index._reference.id == option)])
      })
      return options
    },
    /**
     * Get the price for current configuration, if any.
     */
    price(state, getters) {
      let price = 0
      getters._options
        .filter((option) => state.configuration.options.includes(option._reference.id))
        .forEach((option) => {
          price += parseInt(option.price)
        })
      return price + parseInt(getters.product.price)
    },
    /**
     * Get the discount for current configuration, if any.
     */
    discount(state, getters) {
      let discount = 0
      getters._options
        .filter((option) => state.configuration.options.includes(option._reference.id))
        .forEach((option) => {
          discount += parseInt(option.discount || 0)
        })
      return discount
    },
  },
  mutations: {
    [SET_PRODUCTS](state, payload) {
      state.products = payload
    },
    [SET_CATEGORIES](state, payload) {
      state.categories = payload
    },
    [SET_OPTIONS](state, payload) {
      state.options = payload
    },
    [SET_SETTINGS](state, payload) {
      state.settings = payload
    },
    [SET_STATE](state, payload) {
      state.state = payload
    },
    [SET_CONFIGURATION](state, payload) {
      state.configuration = payload
    },
    [SET_FIRST](state, payload) {
      state.first = payload
    },
  },
  actions: {
    async initializeStore({ dispatch, commit }) {
      try {
        await dispatch('authentication/initializeModule', {}, { root: true })
        await dispatch('api/initializeModule', {}, { root: true })
        commit(SET_STATE, ConfigureState.Select)
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * Flags this as the first configuration.
     */
    flag({ state, commit }) {
      if (state.first) {
        commit(SET_FIRST, false)
      }
    },
    /**
     * Goes to the given step of the configuration.
     */
    goTo({ commit }, configureState) {
      commit(SET_STATE, configureState)
    },
    /**
     * Goes to the next step of the configuration.
     */
    next({ state, commit }) {
      switch (state.state) {
        case ConfigureState.Select:
          commit(SET_STATE, ConfigureState.Configure)
          break
        case ConfigureState.Configure:
          commit(SET_STATE, ConfigureState.Request)
          break
        case ConfigureState.Request:
          commit(SET_STATE, ConfigureState.Sent)
          break
        default:
          break
      }
    },
    /**
     * Goes to the previous step of the configuration.
     */
    pervious({ state, commit }) {
      switch (state.state) {
        case ConfigureState.Configure:
          commit(SET_STATE, ConfigureState.Select)
          break
        case ConfigureState.Request:
          commit(SET_STATE, ConfigureState.Configure)
          break
        default:
          break
      }
    },
    /**
     * Resets the configuration to it's default configuration.
     */
    reset({ state, commit }) {
      commit(SET_CONFIGURATION, {
        product: state.configuration.product,
        options: state.options.filter((option) => option.product == state.configuration.product && option.standard).map((option) => option.id),
      })
    },
    /**
     * Restart the configurator.
     */
    restart({ commit }) {
      commit(SET_STATE, ConfigureState.Select)
    },
    /**
     * Selects a product for the configuration.
     */
    select({ state, commit }, product) {
      commit(SET_CONFIGURATION, {
        product: product._reference.id,
        options: state.api.cache.options.filter((option) => option.product.some((p) => p.id === product._reference.id) && option.standard).map((option) => option._reference.id),
      })
    },
    /**
     * Toggles an option for the configuration.
     */
    toggleOption({ state, commit, getters }, { category, option, update = true }) {
      let options = state.configuration.options
      if (!options.includes(option._reference.id)) {
        if (!category.multiple && getters.options.filter((option) => option.category.id == category._reference.id).length) {
          getters.options
            .filter((option) => option.category.id == category._reference.id)
            .forEach((option) => {
              options.splice(options.indexOf(option._reference.id), 1)
            })
        }
        options.push(option._reference.id)
      } else {
        if (!category.required || getters.options.filter((option) => option.category.id == category._reference.id).length > 1) {
          options.splice(options.indexOf(option._reference.id), 1)
        }
      }
      // update the url
      if (update == true) {
        const concatenated = options.join(';')
        const encoded = btoa(concatenated)
        router.replace({
          path: router.path,
          query: {
            ...router.currentRoute.query,
            options: encoded,
          },
        })
      }
      commit(SET_CONFIGURATION, {
        product: state.configuration.product,
        options,
      })
    },
    /**
     * Sends the request for the configuration.
     */
    request({ state, dispatch }, { request }) {
      return dispatch('functions/request', { request, configuration: state.configuration }, { root: true })
    },
  },
  modules: {
    authentication,
    api,
    functions,
  },
})

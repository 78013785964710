<template>
  <router-view v-if="!busy" />
</template>

<script>
import { mapActions } from 'vuex'
import base from '@/mixins/base'

export default {
  name: 'vHome',
  mixins: [base],
  data() {
    return {
      busy: true,
    }
  },
  metaInfo() {
    return {
      title: 'Køl og Komfur',
    }
  },
  async created() {
    try {
      await this.initializeStore()
      this.busy = false
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    ...mapActions(['initializeStore']),
  },
}
</script>

import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { SET_CACHE } from './mutations'

export default {
  namespaced: true,
  state: {
    cache: undefined,
  },
  mutations: {
    [SET_CACHE](state, payload) {
      state.cache = payload
    },
  },
  actions: {
    async initializeModule({ state, commit }) {
      const documentSnapshot = await getDoc(doc(getFirestore(state.app), '_cache', 'CoGEnpNIVuexy5SNhojT2'))
      const documentData = documentSnapshot.data()
      documentData.products.sort((a, b) => a.name.localeCompare(b.name))
      documentData.settings = documentData.settings[0]
      documentData.options.sort((a, b) => {
        return parseInt(a.price) - parseInt(b.price)
      })
      commit(SET_CACHE, documentData)
    },
  },
}

<template>
  <div class="masonry">
    <figure v-for="(image, index) in images" :key="index">
      <img :src="image" alt="gallery" />
    </figure>
  </div>
</template>

<script>
export default {
  name: 'cMasonry',
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_utilities';
.masonry {
  column-count: 2;
  padding: 6px;
  column-gap: 6px;
  figure {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 6px;
    break-inside: avoid;
  }
  img {
    max-width: 100%;
    display: block;
    grid-row: 1 / -1;
    grid-column: 1;
    margin: 0 auto;
  }
}
</style>

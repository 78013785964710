<template>
  <div>
    <b-container v-show="!busy" fluid class="configure">
      <b-row class="row">
        <cHeader :white="true" class="header mobile" />
        <b-col cols="12" lg="7" class="configure__left" ref="scrolling" style="background-color: white">
          <cHeader class="header desktop" />
          <div class="configure__preview">
            <cPreview />
          </div>
          <cMasonry class="masonry desktop" :images="product.gallery" />
          <div class="rocket" ref="rocket" data-show="false" v-on:click="scrollToTop">
            <b-icon icon="arrow-up" aria-hidden="true"></b-icon>
          </div>
          <div class="share" v-on:click="share">
            <div style="position: fixed; top: -65px; left: -50px; white-space: nowrap; z-index: 1337">
              <b-alert style="background-color: #a7976e; color: white; border-color: #e5ebf5; font-size: 16px" :show="dismissCountDown" variant="warning" @dismissed="dismissCountDown = 0">Copied</b-alert>
            </div>
            <b-icon icon="share" aria-hidden="true"></b-icon>
          </div>
        </b-col>
        <b-col cols="12" lg="5" class="configure__right">
          <div class="configure__settings">
            <div class="configure__body">
              <div class="configure__header">
                <p class="configure__product">{{ product.name }}</p>
                <div class="configure__details">
                  <p class="configure__sku">Varenummer: {{ product.sku }}</p>
                  <div class="status">
                    <div class="status__circle"></div>
                    <p class="status__text">Få på lager</p>
                  </div>
                </div>
              </div>
              <div class="configure__main">
                <div v-for="(category, index) in categories" :key="category.id">
                  <div v-b-toggle="`category-${index}`" class="category">
                    <p class="category__name">{{ category.name }}</p>
                    <div class="plus change">
                      <div class="bar1"></div>
                      <div class="bar2"></div>
                    </div>
                  </div>
                  <b-collapse class="options" :id="`category-${index}`" accordion="category">
                    <!-- LIST -->
                    <div class="list" v-if="category.view == 'list'">
                      <template v-for="option in category.options">
                        <div
                          class="list__option"
                          :key="option.id"
                          v-on:click="
                            toggleOption({ category, option })
                            scrollToTop()
                          "
                          :class="{ selected: isOptionSelected(option) }">
                          <div class="list__left">
                            <img class="list__image" :src="option.thumbnail" :alt="option.name" />
                            <div>
                              <p class="list__name">{{ option.name }}</p>
                              <p v-if="option.description" class="list__description">{{ option.description }}</p>
                              <div class="list__price">
                                <p class="price" :class="{ strike: onDiscount(option) }">{{ formatToCurrency(option.price, Currency.DKK) }}</p>
                                <p v-if="onDiscount(option)" class="discount">{{ formatToCurrency(option.price - option.discount, Currency.DKK) }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <!-- GRID -->
                    <div class="grid py-3" v-if="category.view == 'grid'">
                      <b-container fluid>
                        <b-row>
                          <b-col
                            cols="3"
                            class="grid__option"
                            v-for="option in category.options"
                            :key="option.id"
                            v-on:click="
                              toggleOption({ category, option })
                              scrollToTop()
                            ">
                            <img class="grid__image" :src="option.thumbnail" alt="" :class="{ selected: isOptionSelected(option) }" />
                            <p class="grid__name">{{ option.name }}</p>
                            <div class="grid__price">
                              <p class="price" :class="{ strike: onDiscount(option) }">{{ formatToCurrency(option.price, Currency.DKK) }}</p>
                              <p v-if="onDiscount(option)" class="discount">{{ formatToCurrency(option.price - option.discount, Currency.DKK) }}</p>
                            </div>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </b-collapse>
                </div>
                <cMasonry class="masonry mobile" :images="product.gallery" />
              </div>
            </div>
            <div class="footer">
              <div class="footer__price">
                <p class="price" :class="{ strike: discount != 0 }">{{ formatToCurrency(tweened.toFixed(0), Currency.DKK) }}</p>
                <p v-if="discount != 0" class="discount">{{ formatToCurrency(tweened.toFixed(0) - tweened2.toFixed(0), Currency.DKK) }}</p>
                <p v-if="discount != 0" class="save">Spar {{ formatToCurrency(tweened2.toFixed(0), Currency.DKK) }}</p>
              </div>
              <p class="footer__button" v-on:click="next()">
                Bliv kontaktet
                <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-show="busy" style="background-color: #292b29">
      <div class="spinner">
        <div>
          <b-spinner></b-spinner>
          <p>Indlæser {{ product.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import base from '@/mixins/base'
import cHeader from '@/components/cHeader.vue'
import cPreview from '@/components/cPreview.vue'
import cMasonry from '@/components/cMasonry.vue'
import { formatToCurrency, Currency } from '@/utils'

export default {
  name: 'lConfigure',
  components: {
    cHeader,
    cPreview,
    cMasonry,
  },
  mixins: [base],
  data() {
    return {
      Currency,
      busy: true,
      tweened: 0,
      tweened2: 0,
      dismissCountDown: 0,
    }
  },
  watch: {
    price(n) {
      gsap.to(this, { duration: 0.5, tweened: Number(n) || 0 })
    },
    discount(n) {
      gsap.to(this, { duration: 0.5, tweened2: Number(n) || 0 })
    },
  },
  created() {
    setTimeout(() => {
      this.busy = false
    }, 1000)
  },
  mounted() {
    this.tweened = this.price
    this.tweened2 = this.discount
    this.$refs.scrolling.addEventListener('scroll', () => {
      if (this.$refs.scrolling.scrollTop < window.innerHeight / 4) {
        this.$refs.rocket.dataset.show = false
      } else {
        this.$refs.rocket.dataset.show = true
      }
    })

    if (!this.$route.params.product) {
      this.$router.replace({
        path: '/' + this.product.name.replace(' ', '-').toLowerCase(),
        query: this.$route.query,
      })
    }
    if (this.$route.query.options) {
      this.flag()
      var decoded = atob(decodeURIComponent(this.$route.query.options))
      decoded = decoded.split(';')
      let options = decoded.map((str) => {
        let option = this._options.find((obj) => obj._reference.id === str)
        option = { ...option }
        option.category = this.categories.find((category) => category._reference.id === option.category.id)
        return option
      })
      options.forEach((option) => {
        this.toggleOption({
          category: option.category,
          option,
          update: false,
        })
      })
    }
  },
  methods: {
    formatToCurrency,
    scrollToTop() {
      this.$refs.scrolling.scrollTo({ top: 0, behavior: 'smooth' })
    },
    share() {
      const currentUrl = window.location.href
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          console.log('URL copied to clipboard successfully!')
          this.dismissCountDown = 2
        })
        .catch((err) => {
          console.error('Failed to copy URL: ', err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_utilities';
.header {
  &.desktop {
    @media only screen and (max-width: $LG) {
      display: none;
    }
  }
  &.mobile {
    position: static;
    padding: 7px 14px;
    display: none;
    background-color: #292b29;
    height: 50px;
    width: 100%;
    @media only screen and (max-width: $LG) {
      display: block;
    }
  }
}
.configure {
  height: 100vh;
  padding: 0 !important;
  .row {
    height: 100%;
    margin: 0 !important;
  }
  &__left {
    height: 100vh;
    overflow-y: scroll;
    padding: 0 !important;
    position: relative;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      // background: #343634;
    }
    &::-webkit-scrollbar-thumb {
      background: #a7976e;
    }
    @media only screen and (max-width: $LG) {
      height: 300px;
    }
  }
  &__right {
    height: 100%;
    padding: 0;
    background-color: #292b29;
    position: fixed;
    right: 0;
    top: 0;
    @media only screen and (max-width: $LG) {
      position: static;
      height: auto;
    }
  }
  &__preview {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
  &__settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__body {
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    // -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    padding: 117.29px 58px;
    padding-bottom: 0;
    overflow-y: scroll;
    @media only screen and (max-width: $LG) {
      padding: 26px 14px;
      padding-bottom: 150px;
    }
  }
  &__header {
    margin-bottom: 58.31px;
    @media only screen and (max-width: $LG) {
      margin-bottom: 25px;
    }
  }
  &__product {
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    margin-bottom: 12px;
    color: white;
    @media only screen and (max-width: $LG) {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 8px;
    }
  }
  &__details {
    @media only screen and (max-width: $LG) {
      display: flex;
      justify-content: space-between;
    }
  }
  &__sku {
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    color: white;
    opacity: 63%;
    margin-bottom: 14.42px;
    font-weight: 300;
  }
  .category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19.99px 20px;
    margin-bottom: 1rem;
    position: relative;
    border: 2px solid #ffffff1c;
    &__name {
      font-size: 16px;
      line-height: 19px;
      margin: 0;
      color: white;
    }
  }
  .options {
    .list {
      user-select: none;
      padding: 1rem 0;
      &__option {
        cursor: pointer;
        padding: 1rem;
        margin-bottom: 1rem;
        border: 2px solid #ffffff1c;
        &.selected {
          border: 2px solid #a7976e;
        }
      }
      &__left {
        display: flex;
        align-items: center;
        gap: 20px;
      }
      &__price {
        display: flex;
        gap: 10px;
        .price {
          color: white;
          margin: 0;
          &.strike {
            text-decoration: line-through;
            text-decoration-color: #d5bd87;
          }
        }
        .discount {
          color: white;
          margin: 0;
        }
      }
      &__image {
        height: 80px;
        width: auto;
      }
      &__name {
        font-size: 17px;
        margin: 0;
        color: white;
      }
      &__description {
        font-size: 13px;
        color: white;
        opacity: 63%;
        margin: 0;
        font-weight: 300;
      }
    }
    .grid {
      cursor: pointer;
      &__option {
        text-align: center;
        padding: 10px;
        width: calc(100% / 5);
      }
      &__image {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        padding: 2px;
        border: 1px solid #ffffff1c;
        &.selected {
          border: 1px solid #a7976e;
        }
      }
      &__name {
        font-size: 13px;
        margin: 0;
        color: white;
      }
      &__price {
        .price {
          font-size: 12px;
          color: white;
          margin: 0;
          &.strike {
            text-decoration: line-through;
            text-decoration-color: #d5bd87;
          }
        }
        .discount {
          font-size: 12px;
          color: white;
          margin: 0;
        }
      }
    }
  }
  .footer {
    @media only screen and (max-width: $LG) {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
    &__price {
      display: flex;
      gap: 15px;
      justify-content: center;
      background-color: #343634;
      padding: 22.79px 0 23.21px 0;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.38px;
    }
    &__button {
      background-color: #a7976e;
      color: white;
      text-align: center;
      padding: 22.79px 0 23.21px 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      font-weight: 600;
      margin: 0;
      cursor: pointer;
      &:hover {
        svg {
          transform: translateX(10px);
        }
      }
      svg {
        transition: all 200ms;
      }
    }
    .price {
      color: white;
      margin: 0;
      &.strike {
        text-decoration: line-through;
        text-decoration-color: #d5bd87;
      }
    }
    .discount {
      color: white;
      margin: 0;
    }
    .save {
      color: #d5bd87;
      margin: 0;
    }
  }
}

.masonry {
  &.desktop {
    @media only screen and (max-width: $LG) {
      display: none;
    }
  }
  &.mobile {
    display: none;
    padding: 0;
    @media only screen and (max-width: $LG) {
      display: block;
    }
  }
}

.rocket {
  height: 50px;
  width: 50px;
  background-color: #292b29;
  border-radius: 50%;
  position: fixed;
  bottom: 15px;
  left: 15px;
  opacity: 0;
  transition: all 200ms;
  padding: 15px;
  cursor: pointer;
  transform: rotate(45deg);
  svg {
    height: 100%;
    color: white;
    width: 100%;
  }
  &:not([data-show='false']) {
    opacity: 1;
    transform: rotate(0deg);
  }
}

.share {
  height: 50px;
  width: 50px;
  background-color: #292b29;
  border-radius: 50%;
  position: absolute;
  bottom: 15px;
  right: 15px;
  opacity: 0;
  transition: all 200ms;
  padding: 15px;
  cursor: pointer;
  transform: rotate(45deg);
  svg {
    height: 100%;
    color: white;
    width: 100%;
  }
  &:not([data-show='false']) {
    opacity: 1;
    transform: rotate(0deg);
  }
}

.plus {
  cursor: pointer;
  right: 20px;
}
.bar1,
.bar2 {
  width: 20px;
  height: 1.25px;
  background-color: #c4c4c4;
  transition: all 0.4s;
}
.bar2 {
  transform: rotate(90deg);
}
.not-collapsed .change .bar1 {
  transform: rotate(180deg);
}
.not-collapsed .change .bar2 {
  transform: rotate(360deg);
  opacity: 0;
}

.status {
  display: flex;
  align-items: center;
  gap: 12px;
  &__circle {
    -webkit-animation: 2s blink ease infinite;
    -moz-animation: 2s blink ease infinite;
    -ms-animation: 2s blink ease infinite;
    -o-animation: 2s blink ease infinite;
    animation: 2s blink ease infinite;
    background-color: #ffbb6c;
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }
  &__text {
    color: white;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0.38px;
  }
}
@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>

import { initializeApp } from 'firebase/app'
import { SET_APP } from './mutations'

export default {
  namespaced: true,
  state: {
    app: undefined,
  },
  mutations: {
    [SET_APP](state, payload) {
      state.app = payload
    },
  },
  actions: {
    async initializeModule({ state, commit }) {
      try {
        if (!state.app) {
          commit(SET_APP, initializeApp(JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)))
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
}
